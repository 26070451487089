$(() => {
    // shop checkout
    const checkoutPopup = $("#checkoutPopup");
    const checkoutBtn = $("#checkoutOpenBtn");
    const checkoutCloseBtn = $('.checkoutClose');
    const checkoutSuccess = $(".checkoutSuccess");
    const htmlBody = $('html, body');
    var currentFormToValid = "";
    var isAllDropdownSelected = false;
    var isCheckout = false;
    var isBooking = false;
    var checkoutForm = null;

    jQuery.validator.setDefaults({
        debug: true,
        success: "valid"
      });

    $.validator.addMethod("valueNotEquals", function(value, element, arg){
        return arg !== value;
       }, "Value must not equal arg.");


    $('#shopStep2-form').validate({
        errorPlacement: $.noop,
    });

    $('#shopStep1-form').validate({
        errorPlacement: $.noop,
        ignore: [],
        rules: {
            CountryCode: { valueNotEquals: "" },
           },
        messages: {
            CountryCode: { valueNotEquals: "Please select an item!" },
           } 
        });

    $('#shopStep2-form').validate({
        errorPlacement: $.noop,
        ignore: [],
        rules: {
            country1: { valueNotEquals: "" },
        },
        messages: {
            country1: { valueNotEquals: "Please select an item!" },
        } 
    });

    $('#shopStep2-form-checkout').validate({
        errorPlacement: $.noop,
        ignore: [],
        rules: {
            CountryCode: { valueNotEquals: "" },
        },
        messages: {
            CountryCode: { valueNotEquals: "Please select an item!" },
        } 
    });

    $('#shopStep1-form-checkout').validate({
        errorPlacement: $.noop,
        ignore: [],
        rules: {
            adults: { required: '#kids:blank'},
            kids: { required: '#adults:blank' },
        },
        messages: {
            adults: { valueNotEquals: "Please select an item!" },
            kids: { valueNotEquals: "Please select an item!" },
        } 
    });

    $('#shopStep2-form-book').validate({
        errorPlacement: $.noop,
    });

    $('#shopStep1-form-book').validate({
        errorPlacement: $.noop,
        ignore: [],
        rules: {
            Adults: { 
                required: 
                    function() {
                        return (!$("#Kids").val() && !$("#Age").val());
                }
             },
            Kids: { 
                required: 
                    function() {
                        return (!$("#Adults").val() && !$("#Age").val());
                    }
             },
            Age: { 
                required: 
                    function() {
                        return (!$("#Kids").val() && !$("#Adults").val());
                    }
             },
            message: {
                required: false,
            }
        },
        messages: {
            adults: { valueNotEquals: "Please select an item!" },
            kids: { valueNotEquals: "Please select an item!" },
            family: { valueNotEquals: "Please select an item!" },
        } 
    });
    

    function showCheckout(el) {
        checkoutPopup.removeClass('active');
        el.addClass('active');
        htmlBody.addClass('blocked');
        $("span:contains('next step'), span:contains('shop now'), span:contains('booking'), span:contains('send')")
            .parent().addClass('e-button--disabled');
    };

    function setCurrentFormToValid(currentForm) {
        currentFormToValid = '#' + currentForm;
    }

    function checkFormValidity(currentForm) {
        setCurrentFormToValid(currentForm);

        var isValid = $(currentFormToValid).valid();

        if(isValid == true){
            $("button").closest('.e-booking-form__button-container').find('button').removeClass('e-button--disabled');
        }
        else{
            $("button").closest('.e-booking-form__button-container').find('button').addClass('e-button--disabled');
        }
    }

    $('form').change(function() {
        let currentForm = $(this).closest('form').attr("id");
        checkFormValidity(currentForm);
    }).click(function() {
        let currentForm = $(this).closest('form').attr("id");
        checkFormValidity(currentForm);
    });

    checkoutBtn.on("click", function(e) {
        e.preventDefault();
        const popupId = $(this).data("checkout");
        const popupSelector = '.m-booking[data-checkout="' + popupId + '"]';
        const selector = $(popupSelector);

        if (selector.length > 0) {
            showCheckout(selector);
        }
    });

    checkoutCloseBtn.on('click', function(e) {
        e.preventDefault();
        checkoutPopup.removeClass('active');
        checkoutSuccess.removeClass("active");
        htmlBody.removeClass("blocked");
    });

    // show steps
    const checkoutStep = $(".e-booking-form");
    const tabItem = $(".e-tabs__item");
    const tabUnderline = $('.e-tabs__current');

    checkoutStep.first().show();

    function showStep(el) {
        checkoutStep.hide();
        el.fadeIn(500);
    };

    function moveTabsLine(el) {
        let left = el.position().left;

        if (el.parent().hasClass('swiper-slide')) {
            left = el.parent().position().left;

            let swiper = $('.e-tabs--swiper');
            let windowMaxWidth = window.matchMedia("(max-width: 575px)")

            if (windowMaxWidth.matches) {
                swiper.css('transition', '1s');
                swiper.css('left', -left/2);
            }
    
            tabUnderline.css('width', el[0].parentElement.offsetLeft + el[0].offsetWidth);
            tabUnderline.css('left', 0);
        } else {
            tabUnderline.css('width', el[0].offsetLeft + el[0].offsetWidth);
            tabUnderline.css('left', 0);
        }
    };

    function currentTab(el) {
        tabItem.removeClass('active');
        el.addClass('active');
        moveTabsLine(el);
    };

    $('body').on('click', '.nextStep', function(e) {
        e.preventDefault();
        const step = $(this).data('step');
        $(this).addClass('e-button__checked');
        checkoutForm = step;
        if (checkoutForm === null) {
            checkoutForm = checkoutStep.first()[0].dataset.step;
        }

        const checkout = '.e-booking-form[data-step="' + step + '"]';
        const selector = $(checkout);

        const tab = '.e-tabs__item[data-step="' + step + '"]';
        const tabSelector = $(tab);
        if (selector.length > 0) {
            showStep(selector);
            currentTab(tabSelector);
        }
        
        isAllDropdownSelected = false;
        // let currentForm = $(this).closest('form').attr("id");

        // setCurrentFormToValid(currentForm);

        $(currentFormToValid).validate({
            errorPlacement: $.noop
        });

        if ($('#klarna-checkbox :input').is(':checked')) {
            $("button").closest('.e-booking-form__button-container').find('button').removeClass('e-button--disabled')
        } else {
            $("button").closest('.e-booking-form__button-container').find('button').addClass('e-button--disabled')
        }


        // var isValid = $(currentFormToValid).valid();

        // if(isValid == true){
        //     $("button").removeClass('e-button--disabled');
        // }
        // else{
        //     $("button").addClass('e-button--disabled');
        // }
    });

    $("button").ready( function() {
        $("button").closest('.e-booking-form__button-container').find('button').addClass('e-button--disabled');
        const step = $(this).data('step');
        const stepInputs = '.e-booking-form[data-step="' + step + '"] :input:text';
    });

    // payment methods
    const paymentMethods = $(".e-booking-form__method");
    const paymentCheckbox = $(".e-booking-form__payment");

    paymentMethods.first().show();

    function showPaymentMethod(el) {
        paymentMethods.hide();
        el.fadeIn(500);
    }

    paymentCheckbox.on("change", function(e) {
        const method = $(this).data('method');
        const methodSelector = '.e-booking-form__method[data-method="' + method + '"]';
        const selector = $(methodSelector);

        if (selector.length > 0) {
            showPaymentMethod(selector);
        }
    });

    // show red border if input has errors
    // $('input[type="text"],input[type="email"]').on("click keyup", function(e){
    //     e.preventDefault();

    //     if (($(this).parents('.m-booking.active').length)) {
    //         if($(this).val().length) {
    //             if (this.getAttribute("class") == "error") {
    //                 $(this).parent().addClass('error');
    //             } 
    //             if (this.getAttribute("class") == "valid") {
    //                 $(this).parent().removeClass('error');
    //             }
    //         } else {
    //             $(this).parent().addClass('error');
    //         }
    //     }
    // });
});
