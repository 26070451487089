$(() => {
    // load more posts
    const masonryPostsContainer = $(".postsContainerMasonry");
    const masonryPosts = $(".singlePostMasonry");
    const moreBtn = $('.showMoreBtnMasonry');

    if (masonryPostsContainer.length) {
        masonryPosts.slice(6, masonryPosts.length).hide();

        if ( masonryPosts.length <= 6 ) {
            moreBtn.hide();
        }
    }

    moreBtn.on('click', function(e) {
        e.preventDefault();

        const hiddenPosts = $('.singlePostMasonry:hidden');

        hiddenPosts.slice(0, 6).show();
        masonryPostsContainer.masonry();

        if ((hiddenPosts.length - 6) <= 0) {
            $(this).hide();
        }
    });
});
