$(() => {

    const banner_swiper = document.getElementById('bannerSwiper');

    if (banner_swiper) {
        let bannerSwiper = new Swiper(banner_swiper, {
            speed: 600,
			loop: true,
            direction: 'horizontal',
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            autoplay: {
                delay: 5000
            },
            breakpoints: {
                992: {
                    allowTouchMove: false,
                }
            },
            navigation: {
                prevEl: '.e-banner-swiper__navigation-button--prev',
                nextEl: '.e-banner-swiper__navigation-button--next',
            }
        });
    }

    const routes_swiper = document.getElementById('routesSwiper');

    if (routes_swiper) {
        let routesSwiper = new Swiper(routes_swiper, {
            init: false,
            speed: 600,
			initialSlide: 1,
            direction: 'horizontal',
            watchSlidesProgress: true,
            watchSlidesVisibility: true,
            spaceBetween: 20,
            resistanceRatio: 0,
            breakpoints: {
                300: {
                    spaceBetween: 5,
                    slidesPerView: 1.35
                },
                768: {
                    spaceBetween: 20,
                    slidesPerView: 1
                },
                992: {
                    allowTouchMove: false
                }
            },
            loop: true,
            loopAdditionalSlides: 3
        });

        $('.e-routes-swiper__navigation-button--prev').click(function() {
            routesSwiper.slidePrev();
         });

        $('.e-routes-swiper__navigation-button--next').click(function() {
            routesSwiper.slideNext();
        });

        routesSwiper.init();
    }

    // let gallery_swiper = document.getElementById('gallerySwiper');
    const gallerySwiper = $('.e-gallery-swiper');

    if (gallerySwiper) {
        gallerySwiper.each(function () {
            let swiper = new Swiper(this, {
                speed: 600,
                direction: "horizontal",
                resistanceRatio: 0,
                effect: "fade",
                fadeEffect: {
                    crossFade: true
                },
                scrollbar: {
                    el: ".swiper-scrollbar",
                    hide: false
                },
                navigation: {
                    prevEl: ".e-gallery-swiper__navigation-button--prev",
                    nextEl: ".e-gallery-swiper__navigation-button--next",
                    disabledClass: 'disabled'
                },
                pagination: {
                    el: ".e-gallery-swiper__fraction",
                    type: "custom",
                    renderCustom: function(swiper, current, total) {
                        return ("0" + current).slice(-2);
                    }
                },
                breakpoints: {
                    992: {
                        allowTouchMove: false
                    }
                },
                on: {
                    init: function () {
                        // big brain moment, see https://github.com/nolimits4web/swiper/issues/4180
                        setTimeout(() => {
                            window.dispatchEvent(new Event('resize'));
                        }, 500);

                        setTimeout(() => {
                            window.dispatchEvent(new Event('resize'));
                        }, 750);

                        setTimeout(() => {
                            window.dispatchEvent(new Event('resize'));
                        }, 750);

                        setTimeout(() => {
                            window.dispatchEvent(new Event('resize'));
                        }, 1000);
                    }
                }
            });
        })
    }

    const shop_swiper = document.getElementById("shopSwiper");

    if (shop_swiper) {
        let shopSwiper = new Swiper(shop_swiper, {
            speed: 600,
            direction: 'horizontal',
            scrollbar: {
                el: '.swiper-scrollbar',
                hide: false,
            },
            navigation: {
                prevEl: '.e-shop-swiper__navigation-button--prev',
                nextEl: '.e-shop-swiper__navigation-button--next',
                disabledClass: 'disabled'
            },
            breakpoints: {
                992: {
                    allowTouchMove: false,
                }
            },
        });
    }

    const calendar_swiper = document.getElementById('calendarSwiper');

    if (calendar_swiper) {
        let calendarSwiper = new Swiper(calendar_swiper, {
            speed: 600,
            direction: 'horizontal',
            navigation: {
                prevEl: '.e-calendar-swiper__button--prev',
                nextEl: '.e-calendar-swiper__button--next',
            },
            breakpoints: {
                992: {
                    allowTouchMove: false,
                }
            },
        });
    }

    const programs_swiper = $('.e-programs-swiper');

    if (programs_swiper.length) {
        let programsSwiper = new Swiper(programs_swiper, {
            // init: false,
            speed: 600,
            direction: "horizontal",
            watchSlidesProgress: true,
            watchSlidesVisibility: true,
            spaceBetween: 20,
            navigation: {
                prevEl: ".e-programs-swiper__navigation-button--prev",
                nextEl: ".e-programs-swiper__navigation-button--next",
                disabledClass: "disabled"
            },
            breakpoints: {
                320: {
                    slidesPerView: 1
                },
                768: {
                    slidesPerView: 1,
                },
                992: {
                    slidesPerView: 1,
                    allowTouchMove: false,
                }
            }
        });

        // programsSwiper.init();
        // programsSwiper.snapGrid = programsSwiper.slidesGrid.slice(0);
    }

    const swiper_simple = $('.e-simple-slider');

    if (swiper_simple.length) {
        let simpleSlider = new Swiper(swiper_simple, {
            // init: false,
            speed: 600,
            direction: "horizontal",
            watchSlidesProgress: true,
            watchSlidesVisibility: true,
            spaceBetween: 30,
            navigation: {
                prevEl: ".e-simple-slider__navigation .e-simple-slider__navigation-button--prev",
                nextEl: ".e-simple-slider__navigation .e-simple-slider__navigation-button--next",
                disabledClass: "disabled"
            },
            breakpoints: {
                320: {
                    slidesPerView: 'auto'
                },
                768: {
                    slidesPerView: 'auto',
                },
                992: {
                    slidesPerView: 'auto',
                    allowTouchMove: false,
                }
            }
        });
    }

    const swiper_simple_prog = $('.e-simple-slider--program');

    if (swiper_simple_prog.length) {
        let simpleSliderProg = new Swiper(swiper_simple_prog, {
            // init: false,
            speed: 600,
            direction: "horizontal",
            watchSlidesProgress: true,
            watchSlidesVisibility: true,
            spaceBetween: 30,
            navigation: {
                prevEl: ".e-simple-slider--program-nav .e-simple-slider__navigation-button--prev",
                nextEl: ".e-simple-slider--program-nav .e-simple-slider__navigation-button--next",
                disabledClass: "disabled"
            },
            breakpoints: {
                320: {
                    slidesPerView: 1
                },
                768: {
                    slidesPerView: 'auto',
                },
                992: {
                    slidesPerView: 'auto',
                    allowTouchMove: false,
                }
            }
        });
    }

    const featured_slider = $('.m-featured-slider');

    if (featured_slider.length) {
        let featuredSlider = new Swiper(featured_slider, {
            // init: false,
            speed: 600,
            watchSlidesProgress: true,
            watchSlidesVisibility: true,
            spaceBetween: 30,
            navigation: {
                prevEl: ".e-simple-slider__navigation .e-simple-slider__navigation-button--prev",
                nextEl: ".e-simple-slider__navigation .e-simple-slider__navigation-button--next",
                disabledClass: "disabled"
            },
            breakpoints: {
                320: {
                    slidesPerView: 'auto'
                },
                768: {
                    slidesPerView: 'auto',
                },
                992: {
                    slidesPerView: '',
                    allowTouchMove: false,
                },
                1440: {
                    slidesPerView: 4,
                    allowTouchMove: false,
                }
            }
        });
    }

    const tabs_swiper = $('.e-tabs--swiper');

    if (tabs_swiper.length) {
        let tabsSwiper = new Swiper(tabs_swiper, {
            speed: 600,
            direction: "horizontal",
            watchSlidesProgress: true,
            watchSlidesVisibility: true,
            spaceBetween: 0,
            breakpoints: {
                320: {
                    slidesPerView: 3
                },
                768: {
                    slidesPerView: 'auto'
                },
                992: {
                    allowTouchMove: false
                }
            }
        });
    }

});
