$(() => {
    // SVGInject
    SVGInject(document.querySelectorAll("img.injectable"));

    /* 100vh trick */
    function getVhValue() {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    window.addEventListener('resize', () => {
        getVhValue();
    });

    getVhValue();

    //  pressed button state
    $(".e-button").on("click", function(e) {
        // e.preventDefault();
        $(this).addClass("pressed");
        setTimeout(element => {
            $(this).removeClass("pressed");
        }, 1000);
    });

    /* change color of navbar and header on scroll */
    const header = $('#header');
    const menu = $('#mobile-menu');
    const sticky = $('.sticky-header').length;


    function stickHeader() {
        if ($(window).scrollTop() > 0) {
            header.addClass('active');
            menu.addClass('scrolled');
        } else {
            header.removeClass('active');
            menu.removeClass('scrolled');
        }
    };

    if (sticky) {
        stickHeader();

        $(window).scroll(function() {
            stickHeader();
        });
    }

    /*
        change position of underline in nav
    */

    let nav_current = $('.e-nav__current');
    let nav_menu = $('.e-nav__menu');

    function moveLine(el) {
        if (el.length !== 0) {
            nav_current.css('width', el.outerWidth());
            nav_current.css('left', el.position().left);
        }
    };

    moveLine($('.e-nav__menu').find('.current'));

    nav_menu.children('li').hover(function() {
         moveLine($(this));
    });

    $(window).resize(function() {
        moveLine($('.e-nav__menu').find('.current'));
    });

    nav_menu.mouseleave(function() {
        moveLine($('.e-nav__menu').find('.current'));
    });

    /*
        submenu in mobile menu things
    */

    let menu_toggler = $('.e-nav__toggler');
    let mobile_menu = $('.e-mobile-menu');
    let mobile_items = document.getElementById('mobile-menu-items');

    menu_toggler.click(function() {
        $(this).toggleClass('active');
        mobile_menu.toggleClass('active');

        if (mobile_menu.hasClass('active')) {
            bodyScrollLock.disableBodyScroll(mobile_items);
        } else {
            bodyScrollLock.enableBodyScroll(mobile_items);
        }
    });

    $('.e-mobile-menu__menu > li > a').click(function(e) {
        e.preventDefault();

        $('.e-mobile-menu__menu > li').removeClass('current');
        $(this).parent().addClass('current');
        $('.e-mobile-menu__menu > li > .submenu').not($(this).siblings('.submenu')).slideUp();
        $(this).siblings('.submenu').slideToggle();
    });

    // change bg of routes section

    let routes_content = $('.m-routes__content');

    if (routes_content.length) {
        let routes_bg = routes_content.data('color');
        routes_bg ? routes_content.css('background-color', routes_bg) : true;
    }

    /*
        search
    */

    const search = document.getElementById("search");

    $('.search-open').click(function(e) {
        e.preventDefault();
        search.classList.add("active");
        $('.e-search__item').each(function () {
            if ($('.e-search__result', $(this)).length > 5) {
                for (i = 0; i < $('.e-search__result', $(this)).length; i++) {
                    if (i >= 5) {
                        $('.e-search__result', $(this)).eq(i).addClass('hidden');
                    }
                }
            } else {
                $('.e-search__item-more', $(this)).addClass('hidden');
            }
        });
        bodyScrollLock.disableBodyScroll(search);
    });

    $('.search-close').click(function(e) {
        e.preventDefault();
        search.classList.remove("active");
        bodyScrollLock.enableBodyScroll(search);
    });

    // masonry grid for social posts
    let masonry_grid = $('.m-wall__posts');

    if (masonry_grid) {
        masonry_grid.imagesLoaded(function() {
            masonry_grid.masonry({
                itemSelector: '.e-post',
                columnWidth: '.m-wall__sizer',
                gutter: 20,
            });
        });
    }

    /*
        slider filter
    */

    var slider_input = document.getElementById("slider-input");
    var slider_output = document.getElementById("slider-output");
    var slider_range = document.getElementById("slider-range");
    var slider_input_max = 999;
	
	slider_input_max = slider_input == null ? 999 : slider_input.getAttribute('max');

    
    if (slider_input && slider_output && slider_range) {
        var input_value = slider_input.value;

        slider_output.innerHTML = input_value;
        slider_range.style.width = input_value / slider_input_max * 100 + "%";

        slider_input.oninput = function() {
          slider_output.innerHTML = this.value;
          slider_range.style.width = this.value / slider_input_max * 100 + "%";
        }
    };

    if ($(window).width() < 992) {
        $('.e-dropdown').click(function(e) {
            e.preventDefault();
            $(this).toggleClass('active');
        });

        $(document).click(function(e) {
            if ($('.e-dropdown').hasClass('active') && $(e.target).closest('.e-dropdown').length == 0) {
                $('.e-dropdown').removeClass('active')
            }
        });
    }

    var x, i, j, selElmnt, a, b, c;
    /* Look for any elements with the class "e-select": */
    x = document.getElementsByClassName("e-select");
    for (i = 0; i < x.length; i++) {
      selElmnt = x[i].getElementsByTagName("select")[0];
      /* For each element, create a new DIV that will act as the selected item: */
      a = document.createElement("DIV");
      a.setAttribute("class", "e-select__selected");
      a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
      x[i].appendChild(a);
      /* For each element, create a new DIV that will contain the option list: */
      b = document.createElement("DIV");
      b.setAttribute("class", "e-select__items hide");
      for (j = 1; j < selElmnt.length; j++) {
        /* For each option in the original select element,
        create a new DIV that will act as an option item: */
        c = document.createElement("DIV");
        c.innerHTML = selElmnt.options[j].innerHTML;
        c.addEventListener("click", function(e) {
            /* When an item is clicked, update the original select box,
            and the selected item: */
            var y, i, k, s, h;
            s = this.parentNode.parentNode.getElementsByTagName("select")[0];
            h = this.parentNode.previousSibling;
            for (i = 0; i < s.length; i++) {
              if (s.options[i].innerHTML == this.innerHTML) {
                s.selectedIndex = i;
                h.innerHTML = this.innerHTML;
                y = this.parentNode.getElementsByClassName("active");
                for (k = 0; k < y.length; k++) {
                  y[k].removeAttribute("class");
                }
                this.setAttribute("class", "active");
                break;
              }
            }
            h.click();
        });
        b.appendChild(c);
      }
      x[i].appendChild(b);
      a.addEventListener("click", function(e) {
        /* When the select box is clicked, close any other select boxes,
        and open/close the current select box: */
        e.stopPropagation();
        closeAllSelect(this);
        this.nextSibling.classList.toggle("hide");
        this.classList.toggle("active");
        //Popup dropdown check
        if ($(this).closest('.m-booking').length>0) {
            if ($(this)[0].previousElementSibling.options[0].label == $(this)[0].innerHTML && !$(this).hasClass('active')) {
                $(this).addClass('error');
            } else {
                $(this).removeClass('error');
            }
        }
      });
    }

    function closeAllSelect(elmnt) {
      /* A function that will close all select boxes in the document,
      except the current select box: */
      var x, y, i, arrNo = [];
      x = document.getElementsByClassName("e-select__items");
      y = document.getElementsByClassName("e-select__selected");
      for (i = 0; i < y.length; i++) {
        if (elmnt == y[i]) {
          arrNo.push(i)
        } else {
          y[i].classList.remove("active");
        }
      }
      for (i = 0; i < x.length; i++) {
        if (arrNo.indexOf(i)) {
          x[i].classList.add("hide");
        }
      }
    }

    /* If the user clicks anywhere outside the select box,
    then close all select boxes: */
    document.addEventListener("click", closeAllSelect);

    // custom number input

    let input_increase = $('.e-input-number__increase');
    let input_decrease = $('.e-input-number__decrease');

    input_decrease.click(function() {
        var input = $(this).siblings('input');
        input.val(Math.max(0, input.val() - 1));
    });

    input_increase.click(function() {
        var input = $(this).siblings('input');
        input.val(parseInt(input.val())+1);
    });

    // collapse

    let collapse = $('.e-collapse');
    let collapse_animals = $('.e-collapse--animals');

    collapse.click(function() {
        $(this).toggleClass('active');
        $(this).parent().parent().siblings('.m-team__items').slideToggle();
        initGalleryForSwipers();
    });

    collapse_animals.click(function() {
        $(this).parent().parent().parent().next('.m-animals__item').slideToggle();
        initGalleryForSwipers();
    });

    // tabs

    let tabsContainers = $(".e-tabs");

    if (tabsContainers.length) {
        $(".e-tabs").each((idx, tab) => {
            let tabsCurrent = $(tab).find(".e-tabs__current");
            let tabsItem = $(tab).find(".e-tabs__item");

            setTimeout(function () {
                moveTabsLine($(tab).find(".e-tabs__item.active"), tabsCurrent);
            }, 200);

            tabsItem.hover(function () {
                moveTabsLine($(this), tabsCurrent);
            });

            $(window).resize(function () {
                moveTabsLine($(tab).find(".e-tabs__item.active"), tabsCurrent);
            });

            $(".e-tabs").mouseleave(function () {
                moveTabsLine($(tab).find(".e-tabs__item.active"), tabsCurrent);
            });

            tabsItem.click(function () {
                tabsItem.removeClass("active");
                $(this).addClass("active");

                changeTabContent($(this));
            });
        });
    }

    changeTabContent($(".e-tabs__item.active"));

    function moveTabsLine(el, tabsCurrent) {
        let left = el.position().left;

        if (el.parent().hasClass("swiper-slide")) {
            left = el.parent().position().left;
        }

        tabsCurrent.css("width", el.outerWidth());
        tabsCurrent.css("left", left);
    }

    function changeTabContent(el) {
        var id = el.data("id");

        $(".e-tab-content").hide();

        $('.e-tab-content[data-id="' + id + '"]').fadeIn();
    }

    // places

    let places_current = $('.e-places__current');
    let places_item = $('.e-places__navigation-button');

    if (places_item.length) {
        function moveplacesLine(el) {
            places_current.css('width', el.outerWidth());
            places_current.css('left', el.position().left);
        };

        moveplacesLine($('.e-places__navigation-button.active'));

        places_item.hover(function() {
            moveplacesLine($(this));
        });

        $(window).resize(function() {
            moveplacesLine($('.e-places__navigation-button.active'));
        });

        $('.e-places__navigation').mouseleave(function() {
            moveplacesLine($('.e-places__navigation-button.active'));
        });
    }

    function changePlaceContent(el) {
        var id = el.data('id');

        $('.e-places__items').hide();

        $('.e-places__items[data-id="' + id + '"]').fadeIn();
    }

    changePlaceContent($('.e-places__navigation-button.active'));

    places_item.click(function() {
        places_item.removeClass('active');
        $(this).addClass('active');

        changePlaceContent($(this));
    });

    // highlight - select box to show content
    const boxContent = $(".contentBox");
    const firstContentBox = boxContent.first();
    const featuredBox = $(".featuredBox");

    // const boxesSwiper = $(".m-boxes__swiper");

    function showSeletedContent(el) {
        boxContent.hide();
        el.show();
    };

    featuredBox.on("click", function(e) {
        e.preventDefault();
        const boxId = $(this).data("id");
        const boxSelector = '.contentBox[data-id="' + boxId + '"]';
        const selector = $(boxSelector);
        const containerLenght = $('.m-boxes__row').first()[0].offsetWidth;
        featuredBox.removeClass("active");
        $(this).addClass("active");

        if(selector[0].dataset.id === 'box1') {
            $('.m-boxes__row').animate( { scrollLeft: 0 }, 800);
        }
        if(selector[0].dataset.id === 'box2') {
            $('.m-boxes__row').animate( { scrollLeft: containerLenght/5 }, 800);
        }
        if(selector[0].dataset.id === 'box3') {
            $('.m-boxes__row').animate( { scrollLeft: containerLenght/2 }, 800);
        }
        if(selector[0].dataset.id === 'box4') {
            $('.m-boxes__row').animate( { scrollLeft: containerLenght }, 800);

        }

        if (selector.length > 0) {
            showSeletedContent(selector);
        }
    });

    // show first box;
    if ( featuredBox.length > 0 ) {
        firstContentBox.show();
        featuredBox.first().addClass('active');
    }

    // booking
    const booking = document.getElementById("booking");
    // booking.classList.add("active");

    $('.booking-open').click(function(e) {
        e.preventDefault();
        booking.classList.add("active");
        bodyScrollLock.disableBodyScroll(booking);
    });

    $('.booking-close').click(function(e) {
        e.preventDefault();
        booking.classList.remove("active");
        bodyScrollLock.enableBodyScroll(booking);
    });

    // get route underline
    let get_route_current = $('.e-get-route__current');
    let get_route_menu = $('.e-get-route__nav');

    if (get_route_menu.length) {

        function moveLineRoute(el) {
            get_route_current.css('width', el.outerWidth());
            get_route_current.css('left', el.position().left);
        };

        moveLineRoute($('.e-get-route__nav').find('.active'));

        $(window).resize(function() {
            moveLineRoute($('.e-get-route__nav').find('.active'));
        });

        get_route_menu.children('li').hover(function() {
            moveLineRoute($(this));
        });

        get_route_menu.children('li').click(function() {
            get_route_menu.children('li').removeClass('active');
            $(this).addClass('active');
        });

        get_route_menu.mouseleave(function() {
            moveLineRoute($('.e-get-route__nav').find('.active'));
        });
    }

    // contact content
    let contact_choice = $('.e-fav--contact');
    let contact_content = $('.contact-content');

    function getContactContent(el) {
        contact_choice.removeClass('active');
        el.addClass('active');

        contact_content.hide();
        $('.contact-content[data-id="' + el.data('id') + '"]').fadeIn();
    }

    contact_choice.click(function(e) {
        e.preventDefault();
        getContactContent($(this));

        $('html, body').stop().animate({
            scrollTop: $('.m-contact-choice').offset().top + $('.m-contact-choice').innerHeight() - 50
        }, 500);
    });

    getContactContent($(contact_choice[0]));

    // get route inputs
    let route_from = $('.e-get-route__input--from').find('input');
    let route_to = $('.e-get-route__input--to').find('input');
    let route_change = $('.e-get-route__chevron');

    route_change.click(function() {
        var from = route_from.val();
        var to = route_to.val();

        route_from.val(to);
        route_to.val(from);
    });

    // ANIMALS DETAILS - search
    const animalsInput = $(".e-filter__input--focus");
    const animalClear = $(".e-filter__clear");
    const animalResults = $('.e-results');

    function showInputClear(e) {
        e.parents('.e-filter').find('.e-filter__search').fadeOut('fast');
        e.parents('.e-filter').find('.e-filter__clear').fadeIn('slow');
    }
    function showInputSearch(e) {
        e.parents('.e-filter').find('.e-filter__clear').fadeOut('fast');
        e.parents('.e-filter').find('.e-filter__search').fadeIn('slow');
    }


    animalClear.on('click', function(e) {
        $(this).parents('.e-filter').find('input').val('');
        $(this).parents('.e-filter').removeClass('active');
        showInputSearch($(this));

        if (animalResults.length > 0) {
            animalResults.fadeOut("fast");
        }
    });

    animalsInput.on('blur, keyup', function() {
        let thisVal = $(this).val();

        if (thisVal.length > 0) {
            showInputClear($(this));

            if (animalResults.length > 0) {
                animalResults.fadeIn('fast');
            }
        } else {
            showInputSearch($(this));
            $(this).parents('.e-filter').removeClass('active');

            if (animalResults.length > 0) {
                animalResults.fadeOut('fast');
            }
        }
    });

    $('.e-search__item-more').click(function() {
        $(this).parent().children().each(function (){
            $(this).removeClass('hidden');
        });
        $(this).addClass('hidden');
    });
    var rangeSlider = $('input[type="range"]#slider-input');
    $('div.e-filter__slider-value').css(
        'margin-left',
            `${rangeSlider.attr('value') / rangeSlider.attr('max') * 100}% - ${($('div.e-filter__slider-value').outerWidth() / 2)}px`
    );
    $('input[type="range"]#slider-input').on('input', function() {
        var max = this.getAttribute('max');
        $('div.e-filter__slider-value')
            .css('margin-left',
                'calc(' + ($(this).val() / max * 100) + '% - ' + ($('div.e-filter__slider-value').outerWidth() / 2) + 'px)');
      });
});


function initGalleryForSwipers() {
    const gallerySwiper = $('.e-gallery-swiper');

    if (gallerySwiper) {
        let swiper = new Swiper(gallerySwiper, {
            speed: 600,
            direction: "horizontal",
            resistanceRatio: 0,
            effect: "fade",
            fadeEffect: {
                crossFade: true
            },
            scrollbar: {
                el: ".swiper-scrollbar",
                hide: false
            },
            navigation: {
                prevEl: ".e-gallery-swiper__navigation-button--prev",
                nextEl: ".e-gallery-swiper__navigation-button--next",
                disabledClass: 'disabled'
            },
            pagination: {
                el: ".e-gallery-swiper__fraction",
                type: "custom",
                renderCustom: function(swiper, current, total) {
                    return ("0" + current).slice(-2);
                }
            },
            breakpoints: {
                992: {
                    allowTouchMove: false
                }
            }
        });
    }
}
