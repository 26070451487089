$(() => {
    // load more posts
    const postsContainer = $(".postsContainer");
    const posts = $(".singlePost");
    const moreBtn = $('.showMoreBtn');

    if (postsContainer.length > 0 && postsContainer.hasClass('postsContainerSmall')) {
        posts.slice(3, posts.length).hide();

        if ( posts.length <= 3 ) {
            moreBtn.hide();
        }

    } else if (postsContainer.length > 0 && !postsContainer.hasClass('postsContainerSmall')) {
        posts.slice(6, posts.length).hide();

        if ( posts.length <= 6 ) {
            moreBtn.hide();
        }
    }

    moreBtn.click(function(e) {
        e.preventDefault();

        const hiddenPosts = $('.singlePost:hidden');

        hiddenPosts.slice(0, 3).show();

        if ( ( hiddenPosts.length - 3 ) <= 0 ) {
            $(this).hide();
        }
    });
});
